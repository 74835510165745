/* used for refresh, export and search menu */
.btn-menu {
  border: 1px solid rgb(111, 119, 139);
  padding: 10px;
  border-radius: 5px;
}

/* used for Popover */
.circle {
  border-radius: 50%;
  padding: 6px;
  background: #d5d5d5 0% 0% no-repeat padding-box;
  opacity: 1;
}

/* form footer buttons */
.form-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
  text-align: right;
  left: 0;
  z-index: 1;
  border-top: 1px solid #e6ebf1;
}

/* antd drawer */
.ant-drawer-large.ant-drawer-open {
  width: 100%;
}

.ant-drawer-large.ant-drawer-open .ant-drawer-content-wrapper {
  width: 75% !important;
}

.ant-drawer-half.ant-drawer-open {
  width: 100%;
}

.ant-drawer-half.ant-drawer-open .ant-drawer-content-wrapper {
  width: 50% !important;
}

.ant-drawer-small.ant-drawer-open {
  width: 100%;
}

.ant-drawer-small.ant-drawer-open .ant-drawer-content-wrapper {
  width: 25% !important;
}

.ant-drawer-body {
  padding: 15px;
  margin-bottom: 65px;
}

/* ant-collapse css*/
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  font-weight: 700;
  border-radius: 7px;
  background-color: #ccdcf1;
  color: #222631;
}

/* Common CSS for Modal label start */
.common-label-for-modal {
  text-align: center;
  padding: 20px 0px;
  color: #222631;
  font-size: 16px;
  font-weight: 600;
}

/* used for page heading in PageHeader */
.pageHeadingTitle {
  padding: 0px 0px 10px 0px !important;
  margin-top: -15px !important;
  margin-bottom: 10px !important;
}

#filterCSS .ant-form-item {
  margin-bottom: 0px;
}

/* side navigation menu item css */
.ant-menu-item-group-title {
  padding: 0px !important;
}

/* Specifications items internal table form */
.item-form {
  border-radius: 0.5rem;
  width: -webkit-fill-available;
  padding: 0 5px;
}

.generatePoItemInternalTable .data-table-container {
  margin-top: 0px;
  border-radius: 0px 0px 5px 5px;
}

.generatePoItemHeading .item-information {
  background-color: #ccdcf1;
  padding: 5px 10px;
  color: #222631;
  font-weight: 600;
  border-radius: 7px 7px 0px 0px;
}

/* set background color white as per light theme */
body[data-theme="light"] {
  background-color: rgb(255, 255, 255);
}

/* view details page label and value */
.view-label {
  font-size: 13px;
  font-weight: normal;
  margin: 10px;
  padding: 5px 0 5px 0;
}

.view-information {
  font-size: 13px;
  font-weight: 600;
  padding: 5px 0 5px 0;
  margin: 10px;
}

/* ant btn, form item label, form item removed extra padding and margin */
.ant-btn {
  padding: 3px 10px !important;
}

.ant-form-item-label {
  padding: 0px !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

/* media query */
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .ant-drawer-large.ant-drawer-open .ant-drawer-content-wrapper,
  .ant-drawer-half.ant-drawer-open .ant-drawer-content-wrapper,
  .ant-drawer-small.ant-drawer-open .ant-drawer-content-wrapper,
  .ant-drawer.ant-drawer-open .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  .form-footer {
    position: fixed;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1080px) {
  .ant-drawer-large.ant-drawer-open .ant-drawer-content-wrapper,
  .ant-drawer-half.ant-drawer-open .ant-drawer-content-wrapper,
  .ant-drawer-small.ant-drawer-open .ant-drawer-content-wrapper {
    width: 80% !important;
  }
}

/* ANIMATION FADE IN*/
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInAnimation {
  -webkit-animation-name: fadeIn 4.5s !important;
  animation-name: fadeIn 4.5s !important;
  animation: fadeIn 4.5s !important;
}
/* ANIMATION FADEIN END */

.Online,
.Closed,
.Approved,
.Completed {
  color: #5cb85c;
  /* width: 75px; */
  /* height: 24px; */
  padding: 2px 10px 3px;
  border-radius: 7px;
  background-color: rgba(92, 184, 92, 0.15);
}
.Scheduled {
  color: #8001bb;
  /* width: 75px; */
  /* height: 24px; */
  padding: 2px 10px 3px;
  border-radius: 7px;
  background-color: rgba(167, 105, 203, 0.15);
}
.Offline,
.Open,
.Raised,
.urgent_aging {
  color: #e04c4c;
  /* width: 75px; */
  /* height: 24px; */
  padding: 2px 10px 3px;
  border-radius: 7px;
  background-color: rgba(224, 76, 76, 0.15);
}
.Restored,
.In-Progress,
.Paid {
  color: #0984e3;
  width: 80px;
  height: 24px;
  padding: 2px 20px 3px;
  border-radius: 7px;
  background-color: rgba(9, 132, 227, 0.1);
}
.myRow {
  border-radius: 7px;
  box-shadow: 0 0 10px 0 #c9dcf3;
  padding: 10.5px 10.5px 20.5px 5.5px;
}

.myHeader {
  margin-left: 10px;
  font-weight: bold;
}

.mySpan {
  color: #222631;
  margin: 10px 20px 10px 20px;
}
.Acknowledged,
.New,
.aging {
  color: #f39c12;
  width: 80px;
  height: 24px;
  padding: 2px 20px 3px;
  border-radius: 7px;
  background-color: rgba(197, 180, 139, 0.1);
}
.common-data-strip-head {
  margin-top: 10px;
  border: solid 1px #ccdcf1;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(201, 220, 243, 0.5);
}
@media screen and (max-width: 990px) {
  .common-data-strip-head .cdsh-all .col-lg-3 {
    margin-bottom: 20px;
  }
  .common-data-strip-head .cdsh-all .cdsh-divider {
    border-right: none;
  }
}
.noborder.common-data-strip-box {
  border-right: 1px solid #ccdcf1;
}
.common-data-strip-box p svg {
  margin-left: 3px;
  margin-bottom: 4px;
  height: 16px;
  width: 16px;
  /* color: #c9dcf3; */
  color: #bacce2;
}

.common-data-strip-box {
  text-align: center;
  border-right: 1px solid #ccdcf1;
  padding: 19px 0px;
}
.noborder.common-data-strip-box {
  text-align: center;
  border-right: none;
  /* padding: 19px 0px; */
}
.common-data-strip-box p {
  padding: 0;
  margin-bottom: 2px;
}
.common-data-strip-box h4 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 700;
}
.common-data-strip-box h4 span {
  font-size: 21px;
}
.common-data-strip-box h4 span.up-stat {
  color: #8cb496;
}
.common-data-strip-box h4 span.down-stat {
  color: #cc989d;
}

label > span {
  margin-right: 5px !important;
}
.ant-form-item-label {
  font-weight: 500 !important;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Alert {
  color: #deb509 !important;
}
.Fault {
  color: #e04c4c !important;
}

.error-heading {
  font-size: x-large;
  text-align: center;
  font-weight: 700;
  color: #222631;
}
.error-label {
  font-size: large;
  font-weight: 500;
  text-align: center;
  color: #222631;
}

.webcam-container {
  position: relative;
}

/* Media query for smaller screens */
@media only screen and (max-width: 768px) {
  .webcam-container {
    width: 100%;
  }
}

@keyframes blinking {
  0% {
    background-color: #ffffff;
    border: 3px solid #f2e200;
  }
  100% {
    background-color: #fffefe;
    border: 3px solid #ff6969;
  }
}
#blink {
  /* width: 200px; */
  /* height: 200px; */
  animation: blinking 1s infinite;
}

/* Uploaded documents css for mobile view of web-cam capture */
@media (min-width: 750px) {
  .uploaded-documents {
    position: absolute;
    top: 0;
    left: 550px;
  }
}